import samochody1 from '../../files/images/photosPage/samochody1.jpg'
import samochody2 from '../../files/images/photosPage/samochody2.jpg'
import samochody3 from '../../files/images/photosPage/samochody3.jpg'
import samochody4 from '../../files/images/photosPage/samochody4.jpg'
import samochody5 from '../../files/images/photosPage/samochody5.jpg'

const imagesSlider3 = [
    { image: samochody1, title: 'Ford Sierra Cosworth'},
    { image: samochody2, title: 'Samochód Rajdowy'},
    { image: samochody3, title: 'Niebieski Monstertruck'},
    { image: samochody4, title: 'Niebieski Monstertruck'},
    { image: samochody5, title: 'Fiat 125p'},
  ];
  
  export default imagesSlider3;