import mazoretki1 from '../../files/images/photosPage/mazoretki1.jpg'
import mazoretki2 from '../../files/images/photosPage/mazoretki2.jpg'
import mazoretki3 from '../../files/images/photosPage/mazoretki3.jpg'

const imagesSlider8 = [
    { image: mazoretki1, title: 'Mażoretki na scenie'}, 
    { image: mazoretki2, title: 'Mażoretki na scenie'}, 
    { image: mazoretki3, title: 'Mażoretki na scenie'}, 


  ];
  
  export default imagesSlider8;