import koncert2_1 from '../../files/images/photosPage/koncert2_1.jpg'
import koncert2_2 from '../../files/images/photosPage/koncert2_2.jpg'
import koncert2_3 from '../../files/images/photosPage/koncert2_3.jpg'
import koncert2_4 from '../../files/images/photosPage/koncert2_4.jpg'
import koncert2_5 from '../../files/images/photosPage/koncert2_5.jpg'
import koncert2_6 from '../../files/images/photosPage/koncert2_6.jpg'
import koncert2_7 from '../../files/images/photosPage/koncert2_7.jpg'
import koncert2_8 from '../../files/images/photosPage/koncert2_8.jpg'



const imagesSlider7 = [
    { image: koncert2_1, title: 'Muzyk grający na skrzypcach na scenie'}, 
    { image: koncert2_2, title: 'Muzyk grający na gitarze na scenie'}, 
    { image: koncert2_3, title: 'Muzycy grający na scenie'}, 
    { image: koncert2_4, title: 'Muzycy grający na scenie'}, 
    { image: koncert2_5, title: 'Muzyk grający na gitarze śpiewając'}, 
    { image: koncert2_6, title: 'Muzyk grający na gitarze na scenie'}, 
    { image: koncert2_7, title: 'Muzyk grający na gitarze na scenie'}, 
    { image: koncert2_8, title: 'Kobieta śpiewająca na scenie'}, 

  ];
  
  export default imagesSlider7;