import plantacja1 from '../../files/images/photosPage/Plantacja/plantacja1.jpeg'
import plantacja2 from '../../files/images/photosPage/Plantacja/plantacja2.jpeg'
import plantacja3 from '../../files/images/photosPage/Plantacja/plantacja3.jpeg'
import plantacja4 from '../../files/images/photosPage/Plantacja/plantacja4.jpeg'
import plantacja5 from '../../files/images/photosPage/Plantacja/plantacja5.jpeg'
import plantacja6 from '../../files/images/photosPage/Plantacja/plantacja6.jpeg'


const imagesSlider5 = [
    { image: plantacja1, title: 'Plantacja marihuany'}, 
    { image: plantacja2, title: 'Plantacja marihuany'}, 
    { image: plantacja3, title: 'Plantacja marihuany'}, 
    { image: plantacja4, title: 'Plantacja marihuany'}, 
    { image: plantacja5, title: 'Plantacja marihuany'}, 
    { image: plantacja6, title: 'Plantacja marihuany'}, 
  ];
  
  export default imagesSlider5;